export class MachineDeviceSearchRequest {
  fromDate: Date | string;
  toDate: Date | string;
  costCenterID: number;
  routeIDList: number[];
  majorAccountId: number;
  accountLocationList: number[];
  machineNumber: number;
  status: string;
  rpcActivation: string;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
}

export class PogDexDiscrepancySearchRequest {
  costCenterID: number;
  routeIDList: number[];
  majorAccountId: number;
  accountLocationList: number[];
  machineNumber: number;
  status: string;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
}

export interface MachineDeviceSearchItem {
  costCenterID: number,
  accountID: number,
  accountName: string,
  locationID: number, 
  locationName:string,
  machineID: number,
  machineNumber:number,
  dispenseMethodID: number,
  dispenseMethodName:string,
  routeID: number,
  routeNumber: number,
  planogramId: number,
  lastpogEffectiveDate: Date,
  viDeviceId: number,
  vendorCode: string,
  deviceNo: string,
  deviceStartDateTime: Date,
  rpcCompatible: boolean,
  rpcEnabled: boolean,
  rpcEnabledDateTime: Date,
  rpcAutoSend: boolean,
  rpcAutoSendModifiedDateTime: Date,
  rpcSendDate: Date,
  rpcStatus: string,
  errorMessage: string,
  rowIsDirty: boolean
}

export interface PogDexDiscrepancySearchItem {
  costCenterID: number,
  accountID: number,
  accountName: string,
  locationID: number, 
  locationName:string,
  machineID: number,
  machineNumber:number,
  viDeviceID: number,
  vendorCode: string,
  deviceNo: string,
  statusCode: string,
  routeID: number,
  routeNumber: number,
  dexType: string,
  selection: string,
  dexPrice: number,
  currentPogPrice: number,
  productName: string,
  rpcEnabled: boolean,
  rpcEnabledDateTime: Date,
  rpcSend: boolean,
  rpcSendDate: Date,
  details: PogDexDiscrepancySearchDetail[],
  isCollapsed: boolean
}

export interface PogDexDiscrepancySearchDetail {
  machineID: number,
  selectionName: string,
  dexName: string,
  productLongName: string,
  pogPrice: number,
  dexPrice: number
}

export class PogDexDiscrepancyDisplayItem {
  accountName: string;
  locationName: string;
  routeNumber: number;
  deviceNo: string;
  machineId: number;
  machineNumber: number;
  dexType: string;
  selection: string;
  dexPrice: number;
  currentPogPrice: number;
  productLongName: string;
  rpcOn: string;
  rpcSend: boolean;
  rpcSendDate: Date;
  status: string;
  isHeaderItem: boolean;
  isCollapsed: boolean;
}

export interface RouteLineItem {
  displayName: string;
  routeID: number;
  routeNumber: number;
  selected: boolean;
  preselectAllRoutes: boolean;
}

export interface MachineDeviceSearchMetadata {
  totalItemCount: number;
}

export interface PogDexDiscrepancySearchMetadata {
  totalItemCount: number;
}

export class MachineDeviceSearchResponse {
  items: MachineDeviceSearchItem[];
  metadata: MachineDeviceSearchMetadata;
  routeLineItems: RouteLineItem[];
}

export class PogDexDiscrepancySearchResponse {
  items: PogDexDiscrepancySearchItem[];
  metadata: MachineDeviceSearchMetadata;
}

export class MachineDeviceRpcUpdate {
  viDeviceId: number;
  vendorCode: string;
  rpcEnabled: boolean;
  rpcAutoSend: boolean;
}

export class MachineDeviceSelectionPriceRequestSelection {
  name: string;
  dexName: string;
  price: number;
}

export class MachineDeviceSelectionPriceRequest {

  requestGuid: string;
  operationNo: string;
  lob: string = "V";
  vendorCode: string;
  viDeviceID: number;
  deviceNo: string;
  machineNumber: number;
  machineID: number;
  selectionList: Array<MachineDeviceSelectionPriceRequestSelection>;

  constructor() {
    this.selectionList = new Array<MachineDeviceSelectionPriceRequestSelection>();
  }

  private generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }  

  public setFromItem(item: PogDexDiscrepancySearchItem) {
    this.requestGuid = this.generateGUID();
    this.operationNo = `${item.costCenterID}`;
    this.lob = "V";
    this.vendorCode = item.vendorCode.trim();
    this.viDeviceID = item.viDeviceID;
    this.deviceNo   = item.deviceNo;
    this.machineNumber = item.machineNumber;
    this.machineID = item.machineID;

    item.details.forEach(detail=>{
      let selectionItem = new MachineDeviceSelectionPriceRequestSelection();
      selectionItem.name = detail.selectionName;
      selectionItem.dexName = detail.selectionName;
      selectionItem.price = detail.pogPrice;
      this.selectionList.push(selectionItem);
    });
  }


}

export class RpcStatusFilterRequest {
  column: string;
  filter: string;
}

export class iVendLookup {
  iVendLookupID: number;
  typeName: string;
  enumerationValue: string;
  description: string;
}

export interface RPCTrackStatusMetadata {
  totalItemCount: number;
}

export interface RPCTrackStatusItem {
  rpcTrackStatusID: number;
  accountID: number;
  locationID: number;
  accountLocation: string;
  machineID: number;
  machineNumber: number;
  machineSerialNumber: string;
  dexSerialNumber: string;
  updatedMachineModel: string;
  vendorModel: string;
  deviceNo: string;
  partNumber: string;
  upgradeStepsLookupID: number;
  upgradeNeeded: number;
  upgradeComplete: number
}

export interface RPCTrackStatusResponse {
  metadata: RPCTrackStatusMetadata;
  iVendLookups: iVendLookup[];
  items: RPCTrackStatusItem[];
}
