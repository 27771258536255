import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { CsmComponent } from './filter-bar/csm/csm.component';
import { DateRangeComponent } from './filter-bar/date-range/date-range.component';
import { RouteComponent } from './filter-bar/route/route.component';
import { DateComponent } from './filter-bar/date/date.component';
import { OperationsComponent } from './filter-bar/operations/operations.component';
import { AccountsComponent } from './filter-bar/accounts/accounts.component';
import { WarehouseComponent } from './filter-bar/warehouse/warehouse.component';
import { FilterBarService } from './filter-bar-service/filter-bar.service';
import { FilesService } from './file-service/files.service';

import { ColumnHeaderComponent } from './column-header/column-header.component';

import { YesNoPipe } from './yes-no-pipe/yes-no.pipe';

import { StatusComponent } from './filter-bar/status/status.component';
import { ViewedComponent } from './filter-bar/viewed/viewed.component';
import { ReportNameComponent } from './filter-bar/report-name/report-name.component';
import { SwitchComponent } from './switch/switch.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ErrorComponent } from './error/error.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { BasicDropdownComponent } from './basic-dropdown/basic-dropdown.component';
import { MockComponent as MockComponent } from './mock/mock.component';
import { MachineSearchComponent } from './filter-bar/machine-search/machine-search.component';
import { PhotosFilterComponent } from './filter-bar/photos-filter/photos-filter.component';
import { PhotosOverrideComponent } from './filter-bar/photos-override/photos-override.component';
import { RpcActivationComponent } from './filter-bar/rpc-activation/rpc-activation.component';
import { NatlAccountsComponent } from './filter-bar/natl-accounts/natl-accounts.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule
  ],
  declarations: [
    FilterBarComponent,
    CsmComponent,
    DateRangeComponent,
    RouteComponent,
    DateComponent,
    OperationsComponent,
    AccountsComponent,
    ColumnHeaderComponent,
    StatusComponent,
    ViewedComponent,
    ReportNameComponent,
    SwitchComponent,
    DatePickerComponent,
    ErrorComponent,
    BasicModalComponent,
    BasicDropdownComponent,
    YesNoPipe,
    MockComponent,
    WarehouseComponent,
    MachineSearchComponent,
    PhotosFilterComponent,
    PhotosOverrideComponent,
    RpcActivationComponent,
    NatlAccountsComponent
  ],
  providers: [FilterBarService, FilesService],
  exports: [
    FilterBarComponent,
    ColumnHeaderComponent,
    SwitchComponent,
    DatePickerComponent,
    ErrorComponent,
    BasicModalComponent,
    YesNoPipe
  ]
})
export class SharedModule { }
