import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject, AsyncSubject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { CSMLineItem } from '../../routes/no-service-review-service/no-service-review.model';
import { CommonService } from '../../core/common-service/common.service';
import { HttpClient } from '@angular/common/http';
import { AccountLineItem, AccountLocation } from '../../smart-service/smart-service-service/smart-service.model';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import * as moment from 'moment';

@Injectable()
export class FilterBarService {
  currentPage: string;
  refreshedOn: Date;
  autoRefresh = false;
  refreshInterval: BehaviorSubject<number> =  new BehaviorSubject<number>(5);
  tempDate: Date;
  tempDateTo: Date;
  csm: BehaviorSubject<any> = new BehaviorSubject<any>(new Object());
  nonFilteredRoutes: any[];
  operationError: string;
  accountsError: string;
  multipleOperations = false;
  dontPreCheckAllRoutes = false;
  maxWeekDateDifference = false;

  csms:                 BehaviorSubject<any[]> = new BehaviorSubject<any[]>(new Array);
  operations:           BehaviorSubject<any[]> = new BehaviorSubject<any[]>(new Array());
  routes:               BehaviorSubject<any[]> = new BehaviorSubject<any[]>(new Array());
  warehouses:           BehaviorSubject<any[]> = new BehaviorSubject<any[]>(new Array());
  nationalAccounts:     BehaviorSubject<AccountLineItem[]> = new BehaviorSubject<AccountLineItem[]>(new Array());
  accountLocationList:  BehaviorSubject<AccountLocation[]> = new BehaviorSubject<AccountLocation[]>(new Array());
  accounts:             BehaviorSubject<AccountLineItem[]> = new BehaviorSubject<AccountLineItem[]>(new Array());

  date:                 BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  dateTo:               BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  dateFrom:             BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  status:               BehaviorSubject<string> = new BehaviorSubject<string>('');
  rpcActivation:        BehaviorSubject<string> = new BehaviorSubject<string>('');
  viewed:               BehaviorSubject<any[]> = new BehaviorSubject<any[]>(new Array);
  reportName:           BehaviorSubject<any[]> = new BehaviorSubject<any[]>(new Array);

  machineSearch:        BehaviorSubject<string> = new BehaviorSubject<string>('');
  photosOnly:           BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  photosOverride:       BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  machineServiceId:     BehaviorSubject<any> = new BehaviorSubject<any>('');

  updateIsTableDirty:   BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSubmitRPCEnabled:   BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  isActivateRPCEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  dateLabel: string;
  dateRangeLabel: string;
  dateRangeMin: Date;
  dateRangeMax: Date;
  machineSearchError: string;
  dateError: string;
  dateFromError: string;
  dateToError: string;
  tableIsDirty$ = this.updateIsTableDirty.asObservable();
  isSubmitRPCEnabled$ = this.isSubmitRPCEnabled.asObservable();
  isActivateRPCEnabled$ = this.isActivateRPCEnabled.asObservable();

  @Output() activateRPCEvent  = new EventEmitter();
  @Output() submitRPCEvent    = new EventEmitter();
  @Output() selAllEnabled     = new EventEmitter();
  @Output() selAllAutoSend    = new EventEmitter();
  @Output() selectedMajorAcct = new EventEmitter();
  @Output() resetFilters      = new EventEmitter();

  constructor(private _common: CommonService, private _http: HttpClient, private _appSettings: AppsettingsProvider) {
    this.appSettings = this._appSettings.getSettings();
  }
  
  private appSettings: any;

  IntializeAccounts() {
    let costCenterID = Number(this._common.getOperation().operationNo);
    return this._http.get<AccountLineItem[]>(this.appSettings.urlLocations.machineServiceURL +'AccountInfos/' + costCenterID);
  }

  InitializeNationalAccounts() {
    let costCenterID = Number(this._common.getOperation().operationNo);
    return this._http.get<AccountLineItem[]>(this.appSettings.urlLocations.machineServiceURL +'majorAccountInfos/' + costCenterID);
  }

  GetAccountLocations(accountID: Number) {
    let costCenterID = Number(this._common.getOperation().operationNo);
    return this._http.get<AccountLocation[]>(this.appSettings.urlLocations.machineServiceURL+'AccountLocations/'+ costCenterID+ "/" + accountID );
  }

  ResetFilterBar() {
    this.machineSearch.next('');
    this.accountLocationList.value.length=0;
    const df = moment().subtract(7, 'days').toDate();
    const dt = moment().toDate();
    this.dateFrom.next(df);
    this.dateTo.next(dt);
    this.dateFromError = '';
    this.dateToError = '';
    this.machineSearchError = '';
    this.dontPreCheckAllRoutes = false;
    this.maxWeekDateDifference = true;
    this.photosOnly.next(false);
    this.rpcActivation.next('');
    this.status.next('');
    this.resetFilters.emit();
  }

  setTableAsDirty(e: boolean){
    this.updateIsTableDirty.next(e);
  }

  activateRPC() {
    this.activateRPCEvent.emit();
  }

  submitRPC() {
    this.submitRPCEvent.emit();
  }

  selectAllEnabledEvent() {
    this.selAllEnabled.emit();
  }

  selectAllAutoSendEvent() {
    this.selAllAutoSend.emit();
  }

  selectMajorAccount(e: number) {
    this.selectedMajorAcct.emit(e);
  }

  setSubmitRPCEnabled(isEnabled:boolean) {
    this.isSubmitRPCEnabled.next(isEnabled);
  }

  setActivateRPCEnabled(isEnabled:boolean) {
    this.isActivateRPCEnabled.next(isEnabled);
  }
}
