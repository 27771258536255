import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, ViewChildren, QueryList, SimpleChanges } from '@angular/core';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';
import { ISubscription } from 'rxjs/Subscription';
import { AccountLineItem, AccountLocation, RouteLineItem } from '../../../smart-service/smart-service-service/smart-service.model';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
  @Input() public parentData;

  account: AccountLineItem[];
  accountForDisplay: AccountLineItem[];
  accountLoc:AccountLocation[] = [];
  majorAccount: number;
  label: String;
  option: String;
  accountsSubscription: ISubscription;
  accountLocationSubscription: ISubscription;
  majorAccountSubscription: ISubscription;
  accountID: Number;
  selectAll:boolean;
  
 constructor(private _filter: FilterBarService) { }
  
  ngOnInit() {  
    this._filter.accountLocationList.value.length=0;  
    this.label = 'ACCOUNT';
    this.accountsSubscription = this._filter.IntializeAccounts().subscribe((data: AccountLineItem[]) => {
      this.account = data;
      this.accountForDisplay = this.account;  
      this._filter.accounts.next(data);  
    });
    this.majorAccountSubscription = this._filter.selectedMajorAcct.subscribe((data: number) => {
      this.majorAccount = data;
      this.sortAccountsForDisplay();
    });
  }

   ngOnDestroy() {
      this.accountsSubscription.unsubscribe();
   }
 
  ngDoCheck() {
     if (this.parentData == false) {
       this.accountLoc = null;
       this.option = "Select";
       this._filter.accountLocationList.value.length=0;
     }
   }

  showAccountsError() {
    return this._filter.accountsError;
  }

  onChange(e:any) {

      if(this.parentData == true)
      {
        this.accountID = e.target.value;
        this.accountLocationSubscription =this._filter.GetAccountLocations(this.accountID).subscribe((data: AccountLocation[]) => {
         this.accountLoc=data;
         this._filter.accountLocationList.next(data);
         if (!this._filter.dontPreCheckAllRoutes) {     
        
          // Sets all routes to be checked from the start
          this.selectAll = true;
          this.updateAllBoxes();
         }
         else 
         {
          this.checkForSelectAll();
         }
       });  
      }
   }

  updateAllBoxes() {
   const checked = this.selectAll;
   if(this._filter.accountLocationList != null) {
   this.accountLoc.forEach(function (value: AccountLocation) {
    value.selected = checked;
  });
 }
 }

  checkForSelectAll() {
   this.selectAll = this.accountLoc.every(this.areAllBoxesChecked) ? true : false;
 }  

  private areAllBoxesChecked(element: AccountLocation, index, array) {
    return (element.selected === true);
  }

  sortAccountsForDisplay() {
    if (this.majorAccount === null || this.majorAccount === 0){
      this.accountForDisplay = this.account;
    }
    else {
      this.accountForDisplay = this.account.filter((x) => x.majorAccountID === this.majorAccount);
      this.accountLoc = [];
      this._filter.accountLocationList.value.length=0;
    }
  }
}
