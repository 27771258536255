import { Component, Input, OnInit } from '@angular/core';
import { ISubscription } from 'rxjs-compat/Subscription';
import { AccountLineItem, AccountLocation } from '../../../smart-service/smart-service-service/smart-service.model';
import { FilterBarService } from '../../filter-bar-service/filter-bar.service';

@Component({
  selector: 'app-natl-accounts',
  templateUrl: './natl-accounts.component.html',
  styleUrls: ['./natl-accounts.component.scss']
})
export class NatlAccountsComponent implements OnInit {

  @Input() public parentData;

  account: AccountLineItem[];
  accountLoc:AccountLocation[] = [];
  label: String;
  option: String;
  accountsSubscription: ISubscription;
  accountLocationSubscription: ISubscription;
  accountID: Number;
  selectAll:boolean;
  selectedNationalAccount: number;

  constructor(private _filter: FilterBarService) { }

  ngOnInit() {
    this._filter.accountLocationList.value.length=0;  
    this.label = 'NATIONAL ACCOUNT';
    this.accountsSubscription= this._filter.InitializeNationalAccounts().subscribe((data: AccountLineItem[]) => {
      this.account = data;  
      this._filter.nationalAccounts.next(data);  
    });
    this._filter.resetFilters.subscribe(data => {
      this.reset();
    });
  }

  ngOnDestroy() {
    this.accountsSubscription.unsubscribe(); 
 }

  ngDoCheck() {
    if (this.parentData == false) {
      this.accountLoc = null;
      this.option = "Select";
      this._filter.accountLocationList.value.length=0;
  }
}

  showAccountsError() {
    return this._filter.accountsError;
  }
 
  onChange(e: any) {
    this.selectedNationalAccount = Number(e);
    this._filter.selectMajorAccount(this.selectedNationalAccount);
  }

  reset() {
    this.selectedNationalAccount=null;
    this._filter.selectMajorAccount(this.selectedNationalAccount);
  }

// For Thursday:
// 5. account.component.html add *ngIf to dropdown, only show values equal to major acctID or show all if null

}
